import React, { useLayoutEffect, useRef, useState } from "react"
import tw from "twin.macro"
import Footer from "./Footer"
import Header from "./Header"
import Scroll from "../../Icons/Scroll"

const FormPage = ({
  show,
  title,
  paragraph,
  nextButtonText,
  nextButtonDisabled,
  nextButtonOnClick,
  children,
  wrapperCss,
  activeStep
}) => {

  const supporterContentContainer = useRef(null);
    const [hasScrolled, setHasScrolled] = useState(false)
    const [isScrollable, setIsScrollable] = useState(false)

    const onScroll = (e) => {
        const scrollTop = e.target.scrollTop;
        
        if(scrollTop > 3 && !hasScrolled) {
            setHasScrolled(true);
        }
        if(scrollTop < 3) {
            setHasScrolled(false);
        }
    }

    useLayoutEffect(() => {
        const scrollHeight = supporterContentContainer.current.scrollHeight;
        const containerHeight = supporterContentContainer.current.clientHeight;
        if((scrollHeight > containerHeight) && window.innerWidth >= 640) {
            setIsScrollable(true)
        }
        if(supporterContentContainer.current.scrollTop > 3) {
            setHasScrolled(true)
        }
    }, [show])

  return (
    <div ref={supporterContentContainer} onScroll={onScroll} css={[tw`hidden overflow-auto h-full`, show && tw`block`, wrapperCss]}>
      <Header title={title} paragraph={paragraph} />
      <div css={[tw`overflow-visible`]}>{children}</div>
      { isScrollable && <Scroll hide={hasScrolled ? true : false} /> }
      <Footer
        nextButtonText={nextButtonText}
        nextButtonDisabled={nextButtonDisabled}
        nextButtonOnClick={nextButtonOnClick}
      />
    </div>
  )
}

export default FormPage

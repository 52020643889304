import React, { useEffect, useRef, useState } from "react"
import tw, { styled } from "twin.macro"

const field = FieldComponent => props => {
  const [isActive, setIsActive] = useState(props.error)

  return (
    <div css={[tw`relative w-full`]} className="form-field">
      <label
        for={props.name}
        css={[
          tw`absolute top-4 left-2 md:left-5 -mt-1 z-20 text-lg transition-all ease-out transform`,
          isActive && tw`-top-5 -translate-x-4 text-sm`,
          isActive && props?.textWrap && tw`-top-10 md:-top-5`,
          props.error && tw`border-red-800 text-red-800`,
          (isActive && props.error && props.errorWrap) && tw`-top-10 md:-top-5`,
        ]}
      >
        {(isActive && props.error) || props.label}
      </label>
      <FieldComponent
        onFocus={e => setIsActive(true)}
        onBlur={e => (e.target.value === "" ? setIsActive(false) : null)}
        id={props.name}
        css={[tw`relative block z-30`, props.error && tw`ring-2 ring-red-600 text-red-800`, (isActive && props.error && props.errorWrap) && tw`mt-12 md:mt-0`,]}
        {...props}
        />
    </div>
  )
}

export default field

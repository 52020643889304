export const usePricingLevels = () => [
  {
    title: "Fan Level",
    price: 100,
    features: ["Photography", "Social Media"],
  },
  {
    title: "Support Level",
    price: 250,
    features: ["Photography", "Social Media", "Voice Recording"],
  },
  {
    title: "Mega Level",
    price: 500,
    features: ["Photography", "Social Media", "Voice Recording", "Radio Spot"],
  },
  {
    title: "Die Hard Level",
    price: 1000,
    features: [
      "Photography",
      "Social Media",
      "Voice Recording",
      "Radio Spot",
      "TV Spot",
    ],
  },
]

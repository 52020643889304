module.exports = {
  purge: [],
  darkMode: false, // or 'media' or 'class'
  theme: {
    fontFamily: {
      sans: ["Montserrat", "sans-serif"],
    },
    extend: {
      colors: {
        primary: "#b30838",
        primaryDark: "#9e1b32",
        gray: {
          dark: "#231f20",
          mid: "#828a8f",
          light: "#f6f7f9",
        },
      },
      height: {
        '144': '36rem',
      }
    },
  },
  variants: {
    extend: {},
  },
  plugins: [require("@tailwindcss/typography")],
}

import React, { useContext } from "react"
import tw from "twin.macro"
import {
  setActiveSport,
  increaseStep
} from "./actions"
import SponsorSignupContext from "./context"
import FormPage from "./FormPage"
import { useSports } from "../../hooks/useSports"
import {
  Baseball,
  Basketball,
  Football,
  Golf,
  Gymnastics,
  Rowing,
  Soccer,
  Softball,
  Swim,
  Tennis,
  Track,
  Volleyball,
} from "../Icons"


const Sports = ({title, paragraph, ctaText, index}) => {
  const [ { active_step, active_sport },dispatch ] = useContext(SponsorSignupContext)
  const sports = useSports();
  sports.sort((a, b) => {
    let x = a.name.toUpperCase(),
        y = b.name.toUpperCase();
    return x == y ? 0 : x > y ? 1 : -1;
  });
  const icons = {
    "baseball": Baseball,
    "basketball": Basketball,
    "football": Football,
    "golf": Golf,
    "gymnastics": Gymnastics,
    "rowing": Rowing,
    "soccer": Soccer,
    "softball": Softball,
    "swim": Swim,
    "tennis": Tennis,
    "track": Track,
    "volleyball": Volleyball
  }

  return (
    <FormPage
        show={active_step === index}
        title={title}
        paragraph={paragraph}
        nextButtonText={ctaText}
        nextButtonDisabled={!active_sport}
        nextButtonOnClick={() => dispatch(increaseStep())}
    >
      <div css={[tw`bg-white shadow-xl text-gray-dark px-4 lg:px-8 xl:px-16 py-8 mb-8 mx-4 lg:mx-8 xl:mx-16`]}>
        <h4 css={[tw`mb-8 text-center lg:text-left`]}>Sports</h4>
        <div css={[tw`grid md:grid-cols-2 lg:grid-cols-4 row-gap-12 col-gap-8`]}>
          {sports.map(sport => {
            const TagName = icons[sport.icon_url]

            return (
            <div css={[tw`relative`]}>
              <button
                onClick={e => dispatch(setActiveSport(sport))}
                css={[
                  tw`w-full`,
                  sport?.coming_soon && tw`opacity-30 cursor-auto`,
                  active_sport &&
                        active_sport.id === sport.id &&
                        tw`underline`,
                ]}
                disabled={sport?.coming_soon}
              >
                { TagName &&
                  <TagName
                    css={[tw`h-16 w-16 mx-auto text-gray-mid mb-4`]}
                  />
                }
                <span css={[tw`text-primary font-bold uppercase`]}>
                  {sport.name}
                </span>
                {sport?.coming_soon && (
                  <span css={[tw`uppercase block text-xs -mb-4`]}>
                    Coming Soon
                  </span>
                )}
              </button>
            </div>
          )})}
        </div>
      </div>
    </FormPage>
  )
}

export default Sports
import React from "react"
import PropTypes from "prop-types"
import Navigation from "../Navigation"
import tw from "twin.macro"
import Logo from "../Logo"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => {
  return (
    <footer css={[tw`bg-gray-dark relative`]}>
      <div tw="container mx-auto flex items-center justify-center py-8 sm:py-12 sm:px-12">
        <div tw="grid w-full grid-cols-12">
          <div tw="col-span-12 mx-auto flex flex-col justify-center lg:justify-start">
            <Link to="/" aria-label="Home">
              <Logo css={[tw`h-16 mb-4 md:self-start`]} white /> 
            </Link>
            <a css={[tw`mx-auto text-white text-sm md:ml-0 pt-7`,`width: fit-content;`]} href="mailto:admin@thetuscaloosaconnection.com">admin@thetuscaloosaconnection.com</a>
            <a css={[tw`mx-auto text-white text-sm md:ml-0 pb-7`,`width: fit-content;`]} href="tel:2055809467">(205) 580-9467</a>
          </div>
          <div tw="col-span-12 pt-4 text-center lg:mt-2 mx-auto">
            <Navigation.Primary white />
          </div>
          <div tw="col-span-12 text-white text-xs text-center sm:text-sm sm:text-center mt-4">
            <p>
              © {new Date().getFullYear()}, The Tuscaloosa Connection
            </p>
            <a href="/terms-of-use" target="_blank" tw="underline mr-4">
              Terms
            </a>
            <a href="/privacy-policy" target="_blank" tw="underline">
              Privacy Policy
            </a>
          </div>
        </div>
        {/* <Navigation.Secondary white /> 
      <div tw="flex items-center">
        <div tw="">
      <Navigation.Primary white />
        </div>
      </div>*/}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}
export default Footer

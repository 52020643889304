import React, { useContext } from "react"
import tw from "twin.macro"
import { abbrvState } from "../../helpers"
import Button from "../Button"
import { clearState } from "./actions"
import SupporterSignupContext from "./context"
import { submitForm } from "../../api"

const Complete = ({ index, handleClose }) => {
  const [state, dispatch] = useContext(SupporterSignupContext)

  return (
    <div
      css={[
        tw`hidden py-6 px-4 lg:p-16 items-center`,
        "min-height: 550px",
        state.active_step === index && tw`flex`,
      ]}
    >
      <div css={[tw`lg:w-3/4`]}>
        <h4 css={[tw`text-4xl mb-6 md:mb-8 lg:mb-16`]}>
          Thank You For Supporting UA Athletes
        </h4>
        <p css={[tw`text-sm mb-4`]}>
          You should receive a response from TTC in 48hrs.
        </p>
        <div>
          <Button primary onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Complete

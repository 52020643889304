export const usePricingSupporterLevels = () => [
    {
      title: "Supporter Level 1",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 5,
      features: [
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Supporter Level 2",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 10,
      features: [
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Supporter Level 3",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 25,
      features: [
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Supporter Level 4",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 50,
      features: [
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Supporter Level 5",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 75,
      features: [
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Fan Level",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 100,
      features: [
        "Photography", 
        "Social Media",
        "Or",
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Support Level",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 250,
      features: [
        "Photography", 
        "Social Media", 
        "Voice Recording",
        "Or",
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Mega Level",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 500,
      features: [
        "Photography", 
        "Social Media", 
        "Voice Recording", 
        "Radio Spot",
        "Or",
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
    {
      title: "Die Hard Level",
      tagline: "Help support athletes to capitalize on their name, image, and likeness and build their brands",
      price: 1000,
      features: [
        "Photography",
        "Social Media",
        "Voice Recording",
        "Radio Spot",
        "TV Spot",
        "Or",
        "Entry into raffles for merchandise and autographed gear",
        "Your name listed on The Crimson Tradition website as a supporter"
      ],
    },
  ]
  
import React, { useLayoutEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import AthleteSignupContext from '../context'
import Footer from './Footer'
import Header from './Header'
import Scroll from '../../Icons/Scroll'

const AthletesFormPage = ({
    show,
    title,
    paragraph,
    nextButtonText,
    nextButtonLink,
    nextButtonDisabled,
    nextButtonOnClick,
    children,
    wrapperCss,
    activeStep
}) => {

    const athleteContentContainer = useRef(null);
    const [hasScrolled, setHasScrolled] = useState(false)
    const [isScrollable, setIsScrollable] = useState(false)

    const onScroll = (e) => {
        if (e.target.scrollTop !== 0 && !hasScrolled) {
            setHasScrolled(true)
        }
    }

    useLayoutEffect(() => {
        const scrollHeight = athleteContentContainer.current.scrollHeight;
        const containerHeight = athleteContentContainer.current.clientHeight;
        if((activeStep === 0 || scrollHeight > containerHeight) && window.innerWidth >= 640) {
            setIsScrollable(true)
        }
        if(athleteContentContainer.current.scrollTop > 3) {
            setHasScrolled(true)
        }
    }, [show])

    return (
        <div ref={athleteContentContainer} onScroll={onScroll} css={[tw`hidden relative overflow-auto h-full`, show && tw`block`, wrapperCss]}>
            <Header title={title} paragraph={paragraph} />
            <div css={[tw`overflow-visible px-4 lg:px-16 py-4`]}>{children}</div>
            { isScrollable && <Scroll hide={hasScrolled ? true : false} /> }
            <Footer
                nextButtonText={nextButtonText}
                nextButtonLink={nextButtonLink}
                nextButtonDisabled={nextButtonDisabled}
                nextButtonOnClick={nextButtonOnClick}
            />
        </div>
    )
}

export default AthletesFormPage
import React from 'react'
import tw from "twin.macro"

const Header = ({title, paragraph}) => {
    return (
        <div css={[tw`px-6 lg:px-16 pt-10 sm:pt-8`]}>
            <h4 css={[tw`mb-2`]}>{title}</h4>
            <p css={[tw`mb-4`]}>{paragraph}</p>
        </div>
    )
}

export default Header

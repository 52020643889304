
import { graphql, useStaticQuery } from "gatsby"

export const useSports = () => {
  const {
    allSport: { sports },
    allPackages: { packages }
  } = useStaticQuery(graphql`
    {
      allSport {
        sports: nodes {
          coed
          id: sport_id
          name
          icon_url
        }
      }
      allPackages {
        packages: nodes {
          sport {
            id
          }
        }
      }
    }
  `)

  // Setting coming_soon to true if there are no packages associated with the sport.
  for(let i = 0; i < sports.length; i++) {
    if(packages.some(p => p.sport.id === sports[i].id)) {
      sports[i].coming_soon = false;
    } else {
      sports[i].coming_soon = true;
    }
  }

  return sports;
}

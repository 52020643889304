import React, {useContext} from 'react'
import AthletesFormPage from "./FormPage"
import AthleteSignupContext from './context'
import {increaseStep} from './actions'
import { useAthletesRulesData } from '../../hooks/useAthletesRulesData'

const Rules = ({title, paragraph, ctaText, index}) => {
    const [{active_step}, dispatch] = useContext(AthleteSignupContext)
    const content = useAthletesRulesData();
    return (
        <AthletesFormPage
            show={active_step === index}
            title={title}
            paragraph={paragraph}
            nextButtonText={ctaText}
            nextButtonOnClick={() => dispatch(increaseStep())}
        >
            <div 
                dangerouslySetInnerHTML={{ __html: content.html }}
            >
            </div>
        </AthletesFormPage>
    )
}

export default Rules
export const INCREASE_STEP = "INCREASE_STEP"
export const DECREASE_STEP = "DECREASE_STEP"
export const SET_STEP = "SET_STEP"
export const SET_RESULTS = "SET_RESULTS"
export const SORT_RESULTS = "SORT_RESULTS"
export const UDPATE_FIELD = "UDPATE_FIELD"
export const SET_ACTIVE_SPORT = "SET_ACTIVE_SPORT"
export const SET_LOADING_RESULTS = "SET_LOADING_RESULTS"
export const SET_PACKAGE = "SET_PACKAGE"
export const SELECT_PRICING_LEVEL = "SELECT_PRICING_LEVEL"
export const SELECT_DURATION = "SELECT_DURATION"
export const CLEAR_STATE = "CLEAR_STATE"

export const setResults = results => ({
  type: SET_RESULTS,
  payload: results,
})

export const setLoadingResults = bool => ({
  type: SET_LOADING_RESULTS,
  payload: bool,
})

export const sortResults = (key, dir) => ({
  type: SORT_RESULTS,
  payload: {
    key,
    dir,
  },
})

export const setActiveSport = sport => ({
  type: SET_ACTIVE_SPORT,
  payload: sport,
})

export const increaseStep = () => ({
  type: INCREASE_STEP,
})

export const decreaseStep = () => ({
  type: DECREASE_STEP,
})

export const setStep = step => ({
  type: SET_STEP,
  payload: step,
})

export const updateField = (key, value) => ({
  type: UDPATE_FIELD,
  payload: {
    key: key,
    value: value,
  },
})

export const setSelectedPackage = pkg => ({
  type: SET_PACKAGE,
  payload: pkg,
})

export const selectPricingLevel = pricingLevel => ({
  type: SELECT_PRICING_LEVEL,
  payload: pricingLevel,
})

export const selectDuration = duration => ({
  type: SELECT_DURATION,
  payload: duration,
})

export const clearState = () => ({
  type: CLEAR_STATE,
})

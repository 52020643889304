import React from "react"

const Golf = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 41 53"
    >
      <defs>
        <mask
          id="mask"
          x="0"
          y="0"
          width="41"
          height="53"
          maskUnits="userSpaceOnUse"
        >
          <g id="mask-2">
            <path
              id="path-1"
              fill-rule="evenodd"
              fill="#fff"
              d="M0 0h41v53H0V0z"
            />
          </g>
        </mask>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="icon_golf" data-name="icon golf">
            <g mask="url(#mask)">
              <path
                id="Fill-1"
                class="cls-3"
                d="M20.5 38.81a18.27 18.27 0 1118.22-18.27A18.27 18.27 0 0120.5 38.81zM23 50.72h-5l-1.73-8.85a17.89 17.89 0 008.42 0zm18-30.18a20.5 20.5 0 10-27.28 19.37 1.13 1.13 0 000 .55L16 52.08a1.13 1.13 0 001.11.92h6.84a1.13 1.13 0 001.05-.92l2.28-11.62a1.13 1.13 0 000-.55A20.55 20.55 0 0041 20.54z"
              />
            </g>
            <path
              id="Fill-4"
              class="cls-3"
              d="M15.5 6A2.5 2.5 0 1018 8.5 2.5 2.5 0 0015.5 6"
            />
            <path
              id="Fill-6"
              class="cls-3"
              d="M8.5 13a2.5 2.5 0 102.5 2.5A2.5 2.5 0 008.5 13"
            />
            <path
              id="Fill-8"
              class="cls-3"
              d="M8.5 22a2.5 2.5 0 102.5 2.5A2.5 2.5 0 008.5 22"
            />
            <path
              id="Fill-10"
              class="cls-3"
              d="M15.5 29a2.5 2.5 0 102.5 2.5 2.5 2.5 0 00-2.5-2.5"
            />
            <path
              id="Fill-12"
              class="cls-3"
              d="M20.5 18a2.5 2.5 0 10-2.5-2.5 2.5 2.5 0 002.5 2.5"
            />
            <path
              id="Fill-14"
              class="cls-3"
              d="M26.5 11A2.5 2.5 0 1024 8.5a2.5 2.5 0 002.5 2.5"
            />
            <path
              id="Fill-16"
              class="cls-3"
              d="M33.5 18a2.5 2.5 0 10-2.5-2.5 2.5 2.5 0 002.5 2.5"
            />
            <path
              id="Fill-18"
              class="cls-3"
              d="M33.5 22a2.5 2.5 0 102.5 2.5 2.5 2.5 0 00-2.5-2.5"
            />
            <path
              id="Fill-20"
              class="cls-3"
              d="M26.5 29a2.5 2.5 0 102.5 2.5 2.5 2.5 0 00-2.5-2.5"
            />
            <path
              id="Fill-22"
              class="cls-3"
              d="M21.5 22a2.5 2.5 0 102.5 2.5 2.5 2.5 0 00-2.5-2.5"
            />
            <path
              id="Fill-24"
              class="cls-3"
              d="M14.5 22a2.5 2.5 0 10-2.5-2.5 2.5 2.5 0 002.5 2.5"
            />
            <path
              id="Fill-26"
              class="cls-3"
              d="M28.5 17a2.5 2.5 0 102.5 2.5 2.5 2.5 0 00-2.5-2.5"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Golf

export const INCREASE_STEP = "INCREASE_STEP"
export const DECREASE_STEP = "DECREASE_STEP"
export const SET_STEP = "SET_STEP"
export const UDPATE_FIELD = "UDPATE_FIELD"
export const FORM_SUBMITTING = "FORM_SUBMITTING"
export const FORM_SUCCESS = "FORM_SUCCESS"
export const FORM_ERROR = "FORM_ERROR"
export const CLEAR_STATE = "CLEAR_STATE"

export const increaseStep = () => ({
  type: INCREASE_STEP,
})

export const decreaseStep = () => ({
  type: DECREASE_STEP,
})

export const setStep = step => ({
  type: SET_STEP,
  payload: step,
})

export const updateField = (key, value) => ({
  type: UDPATE_FIELD,
  payload: {
    key: key,
    value: value,
  },
})

export const clearState = () => ({
  type: CLEAR_STATE,
})

export const formSubmitting = bool => ({
  type: FORM_SUBMITTING,
  payload: bool,
})

export const formSuccess = msg => ({
  type: FORM_SUCCESS,
  payload: msg,
})

export const formError = msg => ({
  type: FORM_ERROR,
  payload: msg,
})

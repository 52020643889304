import { Link } from "gatsby"
import React from "react"
import tw from "twin.macro"
import { usePrimaryNavigation } from "../../hooks/usePrimaryNavigation"

const Primary = ({ white, black }) => {
  const { navigation } = usePrimaryNavigation()

  return (
    <nav tw="inline-grid">
      <ul tw="flex flex-1 justify-center flex-wrap lg:flex-nowrap items-center">
        {navigation.items.map((item, index) => (
          <li
            key={index}
            css={[
              tw`cursor-pointer uppercase text-xs font-semibold`,
              index !== navigation.items.length - 1 && tw`mr-4 sm:mr-6 md:mr-12`,
              index !== navigation.items.length - 1 && white && tw`flex-none lg:mr-2`,
              white && tw`text-white inline-block p-4`,
              black && tw`text-black hidden lg:inline-block`,
            ]}
          >
            <Link to={`/${item.url}`}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Primary

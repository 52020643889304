import React from "react"

const Rowing = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 61 53"
    >
      <path d="M60.89 46.85l-5.32-11.68-.07-.17a1.2 1.2 0 00-.83-.57l-7.76-1.4L30.3.67l-.07-.13a1.21 1.21 0 00-1.57-.4l-.12.07a1.24 1.24 0 00-.4 1.59l16.64 32.48-2.32 7v.14a1.26 1.26 0 00.22 1l7.46 10 .1.11a1.2 1.2 0 001.42.25l8.71-4.4.12-.07a1.25 1.25 0 00.4-1.46zm-9.45 3.35L45 41.49l2-5.91 6.69 1.2 4.56 10zM36.46 43.26a12.22 12.22 0 01-3.13.41H33a9.77 9.77 0 01-7.36-3.25 1.26 1.26 0 00-1.85 0 9.79 9.79 0 01-7.68 3.24 9.71 9.71 0 01-7.76-3.25 1.24 1.24 0 00-1.9 0A9 9 0 011 43.69a1.17 1.17 0 00-1 1.36 1.21 1.21 0 001.44.95l.39-.08A11.41 11.41 0 007.3 43l.12-.12.08.12a12.22 12.22 0 008.59 3h.38a12.31 12.31 0 008.05-3l.16-.15.17.15a12.35 12.35 0 008.53 3 14.48 14.48 0 003.73-.49 1.16 1.16 0 00.89-1.44 1.23 1.23 0 00-1.54-.81zM36.44 33.18a12 12 0 01-3.37.49h-.35a9.67 9.67 0 01-7.31-3.25 1.25 1.25 0 00-1.84 0A9.65 9.65 0 0116 33.67a9.62 9.62 0 01-7.71-3.25 1.22 1.22 0 00-1.88 0A8.92 8.92 0 011 33.69a1.17 1.17 0 00-1 1.36 1.21 1.21 0 001.43.95l.39-.08A11.33 11.33 0 007.25 33l.12-.12.08.07A12 12 0 0016 36h.38a12.15 12.15 0 008-3l.16-.15.17.15a12.17 12.17 0 008.46 3h.5a13.8 13.8 0 003.53-.59 1.15 1.15 0 00.8-1.45 1.21 1.21 0 00-1.56-.78z" />
    </svg>
  )
}

export default Rowing

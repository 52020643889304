import React, { useReducer } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import tw from "twin.macro"
import Modal from "../Modal"
import Intro from "./Intro"
import Info from "./Info"
import PricingLevels from "./PricingLevels"
import Payment from "./Payment"
import Complete from "./Complete"
import SupporterSignupContext from "./context"
import reducer, { initialState } from "./reducers"
import modal_background from "../../images/modal_bg_texture.jpg"
import { X } from "../Icons"
import { ModalContext } from "../Layout"
import { useFormsData } from "../../hooks/useFormsData"

export const IndividualSupporterSignup = ({ open }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const modalImage = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "sponsor_modal_image.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 800)
        }
      }
    }
  `)

  const stepComponents = {
    Intro,
    Info,
    PricingLevels,
    Payment,
    Complete,
  }
  let { pages } = useFormsData("supporter")
  // steps = steps.map(step => (step.component = stepComponents[step.component]))
  const steps = pages.map(step => ({
    ...step,
    Component: stepComponents[step.component],
  }))

  return (
    <ModalContext.Consumer>
      {({ setActiveModal }) => (
        <SupporterSignupContext.Provider value={[state, dispatch]}>
          <Modal
            open={open}
            modalCss={[
              tw`bg-blend-multiply p-4 w-full lg:max-w-7xl`,
              `background: linear-gradient(146deg, #9E1B32 0%, #9E1B32 50%, #FFF 50.1%, #FFF 100%), url(${modal_background});`,
            ]}
          >
            <div
              css={[
                tw`grid grid-cols-1 sm:grid-cols-8 h-full`,
                "max-height: 100vh",
              ]}
            >
              <div
                css={[
                  tw`col-span-5 overflow-y-auto bg-gray-mid text-white`,
                  "max-height: 90vh",
                ]}
              >
                {steps.map(({ Component, title, paragraph, ctaText }, i) => (
                  <Component
                    title={title}
                    paragraph={paragraph}
                    ctaText={ctaText}
                    key={i}
                    index={i}
                    handleClose={() => setActiveModal(null)}
                  />
                ))}
              </div>
              <button
                onClick={() => setActiveModal(null)}
                css={[tw`absolute z-30 top-8 right-8 leading-none`]}
              >
                <X css={[tw`text-white h-6 w-6`]} />
              </button>
              <div css={[tw`hidden sm:block col-span-3 relative text-white`]}>
                <GatsbyImage
                  image={getImage(modalImage.file)}
                  alt="Bryant Denny Stadium"
                  css={[tw`top-0 right-0 w-full h-full object-cover absolute`]}
                />
                <div
                  css={[
                    tw`absolute top-0 left-0 w-full z-10 flex flex-col text-center items-center justify-center h-full p-8`,
                  ]}
                >
                  <h2 css={[tw`mb-16`]}>
                    Sign up as a{" "}
                    <span className="stroke-text white" css={[tw`block`]}>
                      supporter
                    </span>
                  </h2>
                  <div css={[tw`relative mb-4`]}>
                    <span
                      css={[
                        tw`block absolute w-full text-center text-8xl top-1/2 transform -translate-y-1/2 opacity-50 font-black`,
                      ]}
                    >
                      {state.active_step + 1}
                    </span>
                    <h3 css={[tw`text-2xl`]}>
                      {steps[state.active_step]["title"]}
                    </h3>
                  </div>
                  <div>
                    {steps.map((step, i) => (
                      <div
                        key={i}
                        css={[
                          tw`inline-block h-2 w-8 mr-1 transform -skew-x-12 opacity-50 bg-white`,
                          i <= state.active_step && tw`opacity-100`,
                          i < state.active_step && tw`bg-primary`,
                        ]}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </SupporterSignupContext.Provider>
      )}
    </ModalContext.Consumer>
  )
}

export default IndividualSupporterSignup

import React from "react"

const Football = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 54 54"
    >
      <path d="M50.063 18.412L35.314 3.68c9.905-.927 12.782.302 13.612 1.135.831.828 2.06 3.7 1.137 13.597zm-.42 3.476c-2.316 15.876-12.325 25.876-28.207 28.18L3.698 32.352C5.991 16.452 15.975 6.43 31.838 4.103l17.804 17.785zM4.085 49.624l.238-.288c-.97-.969-1.922-4.107-1.038-13.5l14.668 14.651c-9.839.915-12.751-.308-13.608-1.132l-.26.269zM50.87 2.889C48.563.582 43.101.045 33.157 1.149 14.334 3.241 2.82 14.764.73 33.594-.392 43.682.132 49.163 2.428 51.366c1.558 1.495 4.592 2.23 9.348 2.23 2.366 0 5.157-.181 8.406-.543C39 50.965 50.517 39.443 52.608 20.612c1.105-9.948.57-15.414-1.739-17.723z" />
      <path d="M28.12 16.023a1.32 1.32 0 000 1.87l1.939 1.938-1.976 1.977-1.938-1.94a1.312 1.312 0 00-.935-.387h-.001c-.352 0-.682.138-.931.387-.25.25-.387.58-.387.932 0 .354.137.686.387.936l1.938 1.938-1.975 1.977-1.939-1.938c-.501-.501-1.372-.501-1.868 0a1.321 1.321 0 000 1.866l1.938 1.938-1.975 1.978-1.941-1.94a1.319 1.319 0 10-1.866 1.867l1.941 1.941-1.98 1.98a1.32 1.32 0 101.864 1.87l1.982-1.98 1.941 1.939c.513.514 1.351.514 1.866 0a1.326 1.326 0 000-1.871l-1.94-1.938 1.977-1.975 1.94 1.936a1.32 1.32 0 001.867-1.866l-1.94-1.94 1.975-1.976 1.94 1.94a1.323 1.323 0 001.867 0 1.323 1.323 0 000-1.867l-1.941-1.94 1.977-1.977 1.941 1.94a1.32 1.32 0 001.867-1.866l-1.941-1.94 1.98-1.982c.25-.249.388-.58.388-.934 0-.354-.138-.686-.387-.935a1.324 1.324 0 00-1.866 0l-1.982 1.981-1.939-1.94a1.326 1.326 0 00-1.867 0" />
    </svg>
  )
}

export default Football

import React from "react"

const Tennis = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 53 53"
    >
      <path d="M26.5 0A26.5 26.5 0 1053 26.5 26.49 26.49 0 0026.5 0zM2.43 26.5A24 24 0 019.11 9.86a24.14 24.14 0 016.51 16.64 24.14 24.14 0 01-6.51 16.64A24 24 0 012.43 26.5zm8.43 18.29A26.59 26.59 0 0018 26.5a26.59 26.59 0 00-7.14-18.29 24.05 24.05 0 0131.28 0A26.59 26.59 0 0035 26.5a26.59 26.59 0 007.14 18.29 24 24 0 01-31.28 0zm33-1.65a24.14 24.14 0 01-6.48-16.64 24.14 24.14 0 016.51-16.64 24.07 24.07 0 010 33.28z" />
    </svg>
  )
}

export default Tennis

import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const Button = styled.button(
  ({ primary, primaryDark, secondary, white, outline, disabled }) => [
    tw`px-3 rounded-lg uppercase font-semibold`,
    primary &&
      tw`bg-primary text-white
      hover:(bg-primaryDark)
      active:(bg-primaryDark)
      focus:(bg-primaryDark)`,
    secondary &&
      tw`bg-gray-dark text-white
      hover:(opacity-50)
      active:(opacity-50)
      focus:(opacity-50)`,
    primaryDark &&
      tw`bg-primaryDark text-white
      hover:(bg-primary)
      active:(bg-primary)
      focus:(bg-primary)`,
    white && tw`bg-white text-primary border-2 border-white
      hover:(bg-primaryDark border-primaryDark text-white)
      focus:(bg-primaryDark border-primaryDark text-white)
      active:(bg-primaryDark text-white)`,
    outline && tw`border-2 bg-transparent`,
    outline &&
      primary &&
      tw`border-primary text-primary
      hover:(bg-primaryDark border-primaryDark text-white)
      active:(bg-primaryDark border-primaryDark text-white)
      focus:(bg-primaryDark border-primaryDark text-white)`,
    outline &&
      white &&
      tw`border-white text-white
      hover:(border-white bg-white text-primary)
      active:(border-white bg-white text-primary)
      focus:(border-white bg-white text-primary)`,
    disabled && tw`pointer-events-none bg-gray-dark opacity-25`,
  ]
)

export const ButtonLink = Button.withComponent(Link)
export const ButtonA = Button.withComponent("a")

export default Button

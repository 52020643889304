import React from "react"
import tw from "twin.macro"

const Scroll = ({hide}) => {
    return (
        <div 
            css={[tw`h-10 w-10 flex items-center justify-center bottom-7 sticky z-50 left-1/2 transform -translate-x-1/2 bg-primaryDark shadow cursor-default`,
                hide && tw`hidden`
            ]}>
            <span css={tw`text-white text-3xl pb-3`}>&#x2304;</span>
        </div>
    )
}

export default Scroll

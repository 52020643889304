import React, { useContext } from "react"
import tw from "twin.macro"
import Button from "../Button"
import { increaseStep, selectPricingLevel, selectDuration } from "./actions"
import SponsorSignupContext from "./context"
import { usePricingLevels } from "../../hooks/usePricingLevels"
import FormPage from "./FormPage"

const PricingLevels = ({ index, title, paragraph, ctaText }) => {
  const [{ active_step, selected_pricing_level }, dispatch] =
    useContext(SponsorSignupContext)

  const pricingLevels = usePricingLevels()

  return (
    <FormPage
      show={active_step === index}
      title={title}
      paragraph={paragraph}
      nextButtonText={ctaText}
      nextButtonDisabled={!selected_pricing_level}
      nextButtonOnClick={() => dispatch(increaseStep())}
    >
      <div css={[tw`grid lg:grid-cols-2 gap-2`]}>
        {pricingLevels.map((pricingLevel, i) => (
          <label
            key={i}
            for={`select_pricing_level_${i}`}
            css={[tw`bg-white text-gray-mid relative flex h-64`]}
          >
            <header
              css={[tw`bg-gray-light py-10 px-4 text-center relative flex-1`]}
            >
              <h5 css={[tw`text-primary mb-4 text-lg`]}>
                {pricingLevel.title}
              </h5>
              <p css={[tw`font-black text-5xl mb-2 lg:text-4xl xl:text-5xl`]}>
                ${Number(pricingLevel.price).toLocaleString()}
              </p>
              <small>per month</small>
              <div
                css={[
                  tw`flex items-center justify-center absolute left-0 bottom-0 p-8 w-full lg:px-6 xl:p-8`,
                ]}
              >
                <input
                  type="radio"
                  name="pricing_levels"
                  css={[tw`sr-only`]}
                  onChange={e => dispatch(selectPricingLevel(pricingLevel))}
                  id={`select_pricing_level_${i}`}
                />
                <div
                  css={[
                    tw`border-2 border-primary h-6 w-6 rounded mr-2`,
                    "padding: 2px;",
                  ]}
                >
                  <div css={[tw`w-full h-full rounded-sm`]} />
                </div>
                <span css={[tw`text-primary uppercase font-semibold`]}>
                  Select
                </span>
              </div>
            </header>
            <ul css={[tw`py-4 px-4 text-center leading-loose flex-1`]}>
              <li css={[tw`text-sm mb-4`]}>includes</li>
              {pricingLevel.features.map((feature, index) => (
                <li key={index} css={[tw`font-semibold`]}>
                  {feature}
                </li>
              ))}
            </ul>
          </label>
        ))}
      </div>
      <p css={[tw`px-8 py-4`]}>All packages are a 24-month commitment.</p>
    </FormPage>
  )
}

export default PricingLevels

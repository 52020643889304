import {
  SET_RESULTS,
  INCREASE_STEP,
  UDPATE_FIELD,
  SET_ACTIVE_SPORT,
  SET_LOADING_RESULTS,
  SET_PACKAGE,
  SELECT_PRICING_LEVEL,
  SELECT_DURATION,
  SET_STEP,
  CLEAR_STATE,
  SORT_RESULTS,
  DECREASE_STEP,
} from "./actions"

export const initialState = {
  active_step: 0,
  name: "",
  type: "individual",
  company_name: "",
  company_type: "",
  company_website: "",
  email: "",
  phone: "",
  mailing_street_address: "",
  mailing_city: "",
  mailing_state: "",
  mailing_zip_code: "",
  use_case: "",
  use_name: "false",
  active_sport: null,
  results: [],
  loading_results: false,
  selected_package: null,
  selected_pricing_level: null,
  selected_duration: null,
}

const supporterSignupReducer = (state, action) => {
  switch (action.type) {
    case UDPATE_FIELD:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }

    case SET_ACTIVE_SPORT:
      return {
        ...state,
        active_sport: action.payload,
      }

    case SET_RESULTS:
      return {
        ...state,
        results: action.payload,
      }

    case SET_LOADING_RESULTS:
      return {
        ...state,
        loading_results: action.payload,
      }

    case SORT_RESULTS:
      const sorted = state.results.sort((a, b) => {
        if (action.payload.dir === "ASC") {
          return a[action.payload.key] > b[action.payload.key] ? 1 : -1
        } else {
          return a[action.payload.key] < b[action.payload.key] ? 1 : -1
        }
      })
      console.log(sorted)
      return {
        ...state,
        results: sorted,
      }

    case INCREASE_STEP:
      return {
        ...state,
        active_step: state.active_step + 1,
      }

    case DECREASE_STEP:
      return {
        ...state,
        active_step: state.active_step - 1,
      }

    case SET_STEP:
      return {
        ...state,
        active_step: action.payload,
      }

    case SET_PACKAGE:
      return {
        ...state,
        selected_package: action.payload,
      }

    case SELECT_PRICING_LEVEL:
      return {
        ...state,
        selected_pricing_level: action.payload,
      }

    case SELECT_DURATION:
      return {
        ...state,
        selected_duration: action.payload,
      }

    case CLEAR_STATE:
      return {
        ...initialState,
      }

    default:
      throw new Error()
  }
}

export default supporterSignupReducer

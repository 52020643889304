import React from "react"

const Soccer = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 53 53"
    >
      <path d="M49 12.47a1.27 1.27 0 00-.93-1.47H48a26.48 26.48 0 00-43.14.16 1.17 1.17 0 00-1-.16A1.29 1.29 0 003 12.61l.27 1.06A26.51 26.51 0 00.24 23a1.3 1.3 0 00-.13 1.21C0 24.94 0 25.71 0 26.5a26.35 26.35 0 004 14 1.17 1.17 0 00.88 1.24A26.49 26.49 0 0049 12.47zm1 9.32L47.11 20l1-3.9A23.71 23.71 0 0150 21.79zM46.3 13l-1.5 6-7.12 3.64-.12.07s0 .05-.07.07L27 15.42V7.77l7.32-3.53.13-.07a1.55 1.55 0 00.23-.17 24 24 0 0111.62 9zm-14 23.48H19.68L15.78 25l10-7a1.17 1.17 0 00.38-.08l10.07 7zM31.37 3l-5.59 2.71-4.92-2.51a23.86 23.86 0 015.64-.68 24.38 24.38 0 014.87.48zM17.54 4.26a.38.38 0 00.14.11l6.87 3.51v7.86l-10 7a1.12 1.12 0 00-.21-.17L7.2 18.89 6 14.09a24.07 24.07 0 0111.54-9.83zM4.9 19.92L3.17 21a23.11 23.11 0 011.11-3.51zm-2.28 4.46L7 21.65l6.05 3.13v.09l4.34 12.81-2.78 2.53a1.1 1.1 0 00-.37.34l-7.76-.9A23.85 23.85 0 012.52 26.5c0-.72.04-1.42.1-2.12zm5.84 17.91l6.08.7h.19l3 5.82a24.12 24.12 0 01-9.27-6.52zM21 49.83a1.24 1.24 0 00-.11-.68l-4-7.66L19.62 39H33.34l3.8 3.63.12.1a1.21 1.21 0 00.88.24h.17l-3 5.81a23.89 23.89 0 01-14.36 1zm18.36-3.08l2.12-4.1 3-.32a24.26 24.26 0 01-5.15 4.42zm7.15-7l-5 .52a2.14 2.14 0 00-.19-.13 1.34 1.34 0 00-1.57.31l-1.22.13-3.37-3.23-.12-.1a1.51 1.51 0 00-.22-.11l4.16-12.27v-.08l6.15-3.14 5 3.16.13.07a1 1 0 00.18.05c0 .52.06 1 .06 1.57a23.89 23.89 0 01-4.02 13.25z" />
    </svg>
  )
}

export default Soccer

import React from "react"

const Baseball = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 64 64"
    >
      <path d="M51 3c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12S57.627 3 51 3zm0 2.597a9.403 9.403 0 110 18.806 9.403 9.403 0 010-18.806z" />
      <path d="M50.477 4.014a1.219 1.219 0 011.39 1.02c.484 3.143-.356 6.084-2.552 8.28-2.197 2.197-5.138 3.037-8.281 2.554a1.219 1.219 0 11.37-2.41c2.408.37 4.565-.246 6.187-1.867 1.62-1.622 2.237-3.78 1.867-6.186a1.219 1.219 0 011.02-1.39zM53.685 16.685c2.197-2.196 5.138-3.036 8.281-2.553a1.219 1.219 0 11-.37 2.41c-2.408-.37-4.565.246-6.187 1.867-1.62 1.622-2.237 3.78-1.867 6.186a1.219 1.219 0 11-2.41.371c-.483-3.143.357-6.084 2.553-8.28zM2.453 2.92c-3.516 3.765-3.224 9.508.693 12.964l47.66 41.86-.029.057c-.621 1.347-.355 2.92.8 4.074 1.5 1.5 3.707 1.5 5.207 0l5.09-5.09c1.501-1.501 1.501-3.708 0-5.209l-.172-.162c-1.116-.989-2.673-1.224-3.917-.67l-.067.03L15.89 3.151C12.355-.855 6.44-1.074 2.683 2.682l-.23.238zm11.58 1.86l42.576 48.481a1.236 1.236 0 001.794.073l.101-.113c.334-.417 1.105-.417 1.624.102.535.536.535 1.179 0 1.714l-5.09 5.09c-.536.536-1.18.536-1.715 0-.497-.497-.533-1.087-.106-1.597l.106-.117a1.235 1.235 0 00-.062-1.804L4.775 14.027c-2.931-2.587-3.09-6.853-.345-9.598 2.744-2.744 7.01-2.586 9.602.351z" />
      <path d="M49.803 43.373a1.274 1.274 0 011.833.032c.459.486.482 1.24.079 1.754l-.11.123-6.408 6.345a1.274 1.274 0 01-1.833-.032 1.352 1.352 0 01-.079-1.754l.11-.123 6.408-6.345zM32.121 24.322a1.1 1.1 0 011.648 1.453l-.091.104-7.8 7.799a1.1 1.1 0 01-1.647-1.453l.091-.104 7.8-7.799z" />
    </svg>
  )
}

export default Baseball

import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import tw from "twin.macro"

const Secondary = ({ white }) => {
  const { navigation } = useStaticQuery(graphql`
    {
      navigation: navigationYaml(position: { eq: "secondary" }) {
        items {
          button
          label
          url
        }
      }
    }
  `)

  return (
    <nav tw="inline-grid grid-cols-1 text-center ml-10">
      <ul tw="hidden sm:flex flex-1 justify-end items-center gap-12">
        {navigation.items.map(item => (
          <li
            css={[
              tw`cursor-pointer uppercase text-xs font-semibold`,
              white && tw`text-white`,
            ]}
          >
            <Link to={item.url}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Secondary

import React, { useContext } from "react"
import tw from "twin.macro"
import { abbrvState } from "../../helpers"
import { setSelectedPackage } from "./actions"
import SponsorSignupContext from "./context"

const PackageRow = ({ data, shaded }) => {
  const [{ selected_package, selected_pricing_level }, dispatch] =
    useContext(SponsorSignupContext)

  const checked = selected_package && selected_package.id === data.id

  return (
    <label
      for={`package_${data.id}`}
      className="group"
      css={[
        tw`block bg-white text-gray-dark relative py-8 font-semibold`,
        shaded && tw`bg-gray-light`,
      ]}
    >
      <div css={[tw`flex h-full items-center`]}>
        <div css={[tw`flex px-8 justify-center`]}>
          <small
            css={[tw`absolute top-4 hidden group-hover:block text-gray-mid`]}
          >
            Select
          </small>
          <input
            type="radio"
            id={`package_${data.id}`}
            name="package"
            css={[tw`sr-only`]}
            onChange={() => dispatch(setSelectedPackage(data))}
          />
          <div css={[tw`border-2 border-primary h-6 w-6 rounded`]} />
        </div>
        <p css={[tw`text-lg font-black uppercase`]}>{data.name}</p>
      </div>
      {checked && (
        <div css={[tw`pt-8 px-8`]}>
          <div css={[tw`grid grid-cols-2`]}>
            <div>
              <strong>Athletes</strong>
              <ul>
                {data.athletes.map((athlete, index) => (
                  <li key={index}>{athlete.name}</li>
                ))}
              </ul>
            </div>
            <div>
              <strong>Features</strong>
              <ul>
                {selected_pricing_level.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
          <p css={[tw` italic text-primary mb-8`]}>
            ${selected_pricing_level.price} / mo
          </p>
        </div>
      )}
    </label>
  )
}

export default PackageRow

import React from "react"

const Basketball = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 55 56"
    >
      <path d="M27.5.677C12.312.677 0 12.99 0 28.177c0 15.188 12.312 27.5 27.5 27.5S55 43.365 55 28.177C55 12.99 42.688.677 27.5.677zm0 2.62c13.74 0 24.88 11.14 24.88 24.88 0 13.741-11.14 24.88-24.88 24.88-13.74 0-24.88-11.139-24.88-24.88 0-13.74 11.14-24.88 24.88-24.88z" />
      <path d="M45.142 8.677L47 10.536 9.858 47.677 8 45.82z" />
      <path d="M9.858 8.677L47 45.82l-1.858 1.858L8 10.536z" />
      <path d="M27.583.677a22.673 22.673 0 01-26.09 26.674L1 27.261l.503-2.59A20.038 20.038 0 0025.08 1.66l-.087-.478 2.59-.504zM33.64 33.317a22.668 22.668 0 0119.866-6.312l.494.09-.504 2.59a20.03 20.03 0 00-23.576 23.01l.087.479-2.59.503c-1.439-7.399.894-15.03 6.224-20.36z" />
    </svg>
  )
}

export default Basketball

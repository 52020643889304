import React from "react"
import tw, { GlobalStyles as TWGlobal } from "twin.macro"
import { css, Global } from "@emotion/react"
import tw_config from "../../../tailwind.config"

function calculateStrokeTextCSS(steps) {
  var css = ""
  for (var i = 0; i < steps; i++) {
    var angle = (i * 2 * Math.PI) / steps
    var cos = Math.round(10000 * Math.cos(angle)) / 10000
    var sin = Math.round(10000 * Math.sin(angle)) / 10000
    css +=
      "calc(var(--stroke-width) * " +
      cos +
      ") calc(var(--stroke-width) * " +
      sin +
      ") 0 var(--stroke-color),"
  }

  return css
}

const GlobalStyles = () => {
  return (
    <>
      <TWGlobal />
      <Global
        styles={css`
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            text-transform: uppercase;
            font-weight: 900;
            font-style: italic;
          }

          h1 {
            ${tw`text-5xl xl:text-6xl`}
          }

          h2 {
            ${tw`text-4xl xl:text-5xl`}
          }

          h3 {
            ${tw`text-3xl xl:text-4xl`}
          }

          h4 {
            ${tw`text-2xl xl:text-3xl`}
          }

          h5 {
            ${tw`text-xl xl:text-2xl`}
          }

          .stroke-text {
            --stroke-width: 2px;
            -webkit-text-stroke: var(--stroke-width) var(--stroke-color);
            -webkit-text-fill-color: transparent;
            color: white;
            text-shadow: ${calculateStrokeTextCSS(8)};

            &.primary {
              --stroke-color: ${tw_config.theme.extend.colors.primary};
            }

            &.black {
              --stroke-color: black;

              strong {
                -webkit-text-fill-color: black;
                --stroke-width: 0px;
              }
            }

            &.white {
              --stroke-color: white;
            }

            strong {
              -webkit-text-fill-color: white;
              --stroke-width: 0px;
            }

            span {
              ${tw`text-3xl xl:text-6xl`}
            }
          }

          input:checked + div,
          input:checked + div > div {
            ${tw`bg-primary`}
          }
        `}
      />
    </>
  )
}

export default GlobalStyles

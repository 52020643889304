import React, { useEffect, useState } from "react"
import { Hamburger } from "../Icons"
import tw from "twin.macro"
import { usePrimaryNavigation } from "../../hooks/usePrimaryNavigation"
import { Link } from "gatsby"

const MobileNavigation = () => {
  const { navigation } = usePrimaryNavigation()
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [display, setDisplay] = useState(tw`hidden`)

  useEffect(() => {
    if (open) {
      setDisplay(tw`flex`)
      document.body.style.overflow = "hidden"
      document.body.style.position = "fixed"

      setTimeout(() => {
        setShow(open)
      }, 10)
    } else {
      setShow(open)
      document.body.style.position = "relative"

      setTimeout(() => {
        setDisplay(tw`hidden`)
        document.body.style.overflow = "scroll"
      }, 300)
    }
  }, [open])

  return (
    <div
      css={[
        tw`absolute top-0 left-0 h-full w-full bg-primaryDark bg-opacity-0 transition duration-300 overflow-hidden`,
        show && tw`bg-opacity-90 fixed z-50`,
      ]}
    >
      <div
        css={[
          tw`transform translate-x-full transition duration-300 ease-out h-full w-full`,
          show && tw`translate-x-1/4`,
        ]}
      >
        <button
          onClick={() => setOpen(!open)}
          css={[tw`absolute right-full p-3`]}
          aria-label="Menu Toggle"
        >
          <Hamburger css={[tw`mr-0 h-8`, show && tw`text-white`]} />
        </button>
        <div
          css={[
            tw`bg-gray-mid w-3/4 relative right-0 top-0 h-full shadow-2xl p-4 relative overflow-hidden`,
            display,
          ]}
        >
          <nav css={[tw`w-full z-50`]}>
            {navigation.items.map((navItem, index) => (
              <Link
                key={index}
                onClick={() => setOpen(false)}
                to={`/${navItem.url}`}
                css={[
                  tw`block text-white font-semibold uppercase p-4 border-gray-dark border-opacity-25 border-b`,
                ]}
              >
                {navItem.label}
              </Link>
            ))}
          </nav>
          <div
            css={[
              tw`absolute bottom-0 left-0 right-0 p-4 text-center text-sm text-gray-dark`,
            ]}
          >
            © {new Date().getFullYear()}, The Tuscaloosa Connection
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileNavigation

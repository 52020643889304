import React, { useState, useEffect } from "react"
import tw from "twin.macro"

const Modal = ({ children, modalCss, open, ...remainingProps }) => {
  const [show, setShow] = useState(false)
  const [display, setDisplay] = useState(tw`hidden`)

  useEffect(() => {
    if (open) {
      setDisplay(tw`flex`)
      document.body.style.overflow = "hidden"

      setTimeout(() => {
        setShow(open)
      }, 10)
    } else {
      setShow(open)

      setTimeout(() => {
        setDisplay(tw`hidden`)
        document.body.style.overflow = "scroll"
      }, 300)
    }
  }, [open])

  return (
    <div
      css={[
        tw`p-0 sm:p-6 z-50 transition bg-black bg-opacity-75 fixed top-0 left-0 w-full h-full opacity-0 justify-center items-center pointer-events-none`,
        show && tw`opacity-100 pointer-events-auto`,
        display,
      ]}
    >
      <div
        css={[
          tw`p-16 bg-white transition duration-300 opacity-0 transform translate-y-6 shadow-xl relative max-w-5xl`,
          show && tw`opacity-100 translate-y-0`,
          "max-height: 100vh",
          ...modalCss,
        ]}
      >
        <div css={[tw`h-full`, "max-height: 100vh"]}>{children}</div>
      </div>
    </div>
  )
}

Modal.defaultProps = {
  modalCss: [],
}

export default Modal

import React from "react"

const Track = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 20.12 20.12"
    >
      <path d="M20.12 2.12L18 0l-7.94 7.94L2.12 0 0 2.12l7.94 7.94L0 18l2.12 2.12 7.94-7.94L18 20.12 20.12 18l-7.94-7.94 7.94-7.94z" />
    </svg>
  )
}

export default Track

import React, {useContext} from 'react'
import AthletesFormPage from "./FormPage"
import AthleteSignupContext from './context'
import {
    formSubmitting,
    formError,
    updateField,
    formSuccess,
    increaseStep
  } from "./actions"
import tw from 'twin.macro'
import { Link } from 'gatsby'
import {useAthletesLegalData} from '../../hooks/useAthletesLegalData' 

const Legal = ({title, paragraph, ctaText, ctaLink, index}) => {
    const [state, dispatch] = useContext(AthleteSignupContext)
    const content = useAthletesLegalData();

    const handleForm = async state => {  
        dispatch(formError(false))
        dispatch(formSubmitting(true))
        
        const res = await fetch(`${process.env.GATSBY_API_URL}/athletes`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.GATSBY_API_KEY,
          },
          body: JSON.stringify(state),
        })
        if (res.ok) {
          dispatch(formSubmitting(false))
          dispatch(formSuccess(true))
          dispatch(increaseStep());
          window.location.replace(ctaLink);
        } else {
          dispatch(formSubmitting(false))
          dispatch(formError("Something went wrong. Please try again."))
        }
      }
    return (
        <AthletesFormPage
            show={state.active_step === index}
            title={title}
            paragraph={paragraph}
            nextButtonText={ctaText}
            nextButtonOnClick={() => handleForm(state)}
        >
          <div>
            { state.form_error ? (
              <div css={tw`py-8 sm:py-16`}>{state.form_error}</div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: content.html }} />
            )}
          </div>
        </AthletesFormPage>
    )
}

export default Legal
import React, {useContext} from 'react'
import SponsorSignupContext from './context'
import FormPage from './FormPage'
import { increaseStep } from './actions'
import tw from 'twin.macro'
import { useSponsorRulesData } from '../../hooks/useSponsorRulesData'

const Rules = ({title, paragraph, ctaText, index}) => {
    const [{active_step}, dispatch] = useContext(SponsorSignupContext)
    const content = useSponsorRulesData();
    return (
        <FormPage
            show={active_step === index}
            title={title}
            paragraph={paragraph}
            nextButtonText={ctaText}
            nextButtonOnClick={() => dispatch(increaseStep())}
            activeStep={active_step}
        >
            <div 
                css={[tw`p-6 text-white lg:px-16`,
                    `ul li { list-style: disc; }`
            ]}
                dangerouslySetInnerHTML={{ __html: content.html }}
            >
            </div> 
        </FormPage>
    )
}

export default Rules

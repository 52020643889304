import React, { useContext } from "react"
import SponsorSignupContext from "../context"
import tw from "twin.macro"
import Button from "../../Button"
import { decreaseStep } from "../actions"

const Footer = ({
  nextButtonText,
  nextButtonOnClick,
  nextButtonDisabled,
  backButton,
}) => {
  const [{ active_step }, dispatch] = useContext(SponsorSignupContext)

  return (
    <div css={[tw`flex px-4 md:px-16 py-4`]}>
      {active_step > 0 && (
        <Button
          secondary
          onClick={() => dispatch(decreaseStep())}
          css={[tw`mr-4`]}
        >
          Back
        </Button>
      )}
      <Button
        primary
        onClick={nextButtonOnClick}
        css={[tw`w-full sm:w-auto`]}
        disabled={nextButtonDisabled}
      >
        {nextButtonText}
      </Button>
    </div>
  )
}

export default Footer

import { Link } from "gatsby"
import React, { useContext, useState } from "react"
import tw from "twin.macro"
import { Input } from "../Form"
import {
  formSubmitting,
  formError,
  updateField,
  formSuccess,
  updatePhone,
  increaseStep
} from "./actions"
import SponsorSignupContext from "./context"
import Button from "../Button"
import Loader from "../Loader"
import Select from "../Form/Select"
import { useSports } from "../../hooks/useSports"
import AthletesFormPage from "./FormPage"
import { useUsStates } from "../../hooks/useUsStatesList"

const Info = ({ title, paragraph, ctaText, index }) => {
  const [state, dispatch] = useContext(SponsorSignupContext)
  const [invalidEmail, setInvalidEmail] = useState(false)
  
  const validEmail = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.edu$/, 'i');
  const sports = useSports()
  const usStates = useUsStates()

  const validateForm = () => {
    setInvalidEmail(false)
    const isValid =  validEmail.test(state.email)
    
    if(isValid) {
      dispatch(increaseStep());
    } else {
      setInvalidEmail("Invalid email, must end with .edu.")
    } 
  }

  return (
    <AthletesFormPage
      show={state.active_step === index}
      title={title}
      paragraph={paragraph}
      nextButtonText={ctaText}
      nextButtonOnClick={() => validateForm()}
      nextButtonDisabled={
        !state.name || 
        !state.email || 
        !state.phone || 
        !state.sport_id ||
        !state.hometown_city ||
        !state.hometown_state ||
        !state.opt_in
      }
    >
    <div
      css={[tw`py-4`]}
    >
        <div>
          {state.form_error && (
            <div css={[tw`p-4 mb-8 bg-primaryDark font-semibold rounded`]}>
              Looks like you've registered previously.
            </div>
          )}
          <Input
            name="name"
            label="Name"
            value={state.name}
            onChange={e => dispatch(updateField("name", e.target.value))}
            css={[tw`mb-8`]}
          />
          <Input
            name="email"
            label="Email (ending in .edu)"
            type="email"
            value={state.email}
            onChange={e => dispatch(updateField("email", e.target.value))}
            css={[tw`mb-8`]}
            error={invalidEmail}
          />
          <Input
            name="phone"
            label="Contact Phone"
            type="tel"
            value={state.phone}
            onChange={e => dispatch(updateField("phone", e.target.value))}
            css={[tw`mb-8`]}
            maxLength={11}
          />
          <Input 
            name="hometown_city"
            label="Hometown"
            value={state.hometown_city}
            onChange={e => dispatch(updateField("hometown_city", e.target.value))}
            css={tw`mb-8`}
          />
          <Select 
            name="hometown_state"
            label="State"
            value={state.hometown_state}
            onChange={e => dispatch(updateField("hometown_state", e.target.value))}
            css={tw`mb-8 h-12`}
          >
            <option css={tw`bg-black bg-opacity-70 text-white`}></option>
            { usStates.map((state, index) => (
             <option key={index} value={state} css={tw`bg-black bg-opacity-70 text-white`}>
              {state}
             </option>
            ))
            }
          </Select>
          <Select
            name="sport"
            label="Sport"
            type="text"
            value={state.sport}
            onChange={e => dispatch(updateField("sport_id", e.target.value))}
            css={[tw`mb-8 h-12`]}
          >
            <option css={tw`bg-black bg-opacity-70 text-white`}></option>
            {sports.map((sport, index) => (
              <option key={sport.id} value={sport.id} css={tw`bg-black bg-opacity-70 text-white`}>
                {sport.name}
              </option>
            ))}
          </Select>
          <label for="opt_in" css={[tw`inline-flex items-center`]}>
            <input
              id="opt_in"
              name="opt_in"
              type="checkbox"
              css={[tw`sr-only`]}
              checked={state.opt_in}
              onChange={e => dispatch(updateField("opt_in", e.target.checked))}
            />
            <div
              css={[
                tw`border-2 border-primary h-6 w-6 rounded mr-2 p-2 lg:p-0.5`,
              ]}
            >
              <div css={[tw`w-full h-full rounded-sm`]} />
            </div>
            <p>
              By submitting I agree to TTC's{" "}
              <a href="/terms-of-use" target="_blank" css={[tw`underline`]}>
                Terms
              </a>{" "}
              and {" "}
              <a href="/privacy-policy" target="_blank" css={[tw`underline`]}>
                Privacy Policy
              </a>
              .
            </p>
          </label>
        </div>
    </div>
    </AthletesFormPage>
  )
}

export default Info

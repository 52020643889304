import React from "react"

const Volleyball = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 52.97 54"
    >
      <path d="M53 27.27A26.66 26.66 0 0047.5 11a1.3 1.3 0 00-.78-.49A26.23 26.23 0 0025.64 0a24.6 24.6 0 00-3.21.21 1.27 1.27 0 00-1.07 1 26.14 26.14 0 00-14 7 1.31 1.31 0 00-.4 1v.38a25.92 25.92 0 00-2 33 1.33 1.33 0 00.22 1 26.71 26.71 0 0010.9 8.1 1.29 1.29 0 00.69.65A26.83 26.83 0 0053 29.67a1.32 1.32 0 00-.06-.48c.06-.64.06-1.28.06-1.92zm-2.54 0A25.81 25.81 0 0038.65 24a.8.8 0 00-.15 0 34.81 34.81 0 00-11.69 1.58 17.16 17.16 0 01-3.21-8.72 35 35 0 0122.18-4 24 24 0 014.65 14.41zM20.08 15.83a1.94 1.94 0 00.07.33v.37a19.58 19.58 0 004.25 11.35c-.28 2.36-2.85 5.41-6.84 8.24l-.64.42c-5.23-8-6.42-20.4-6.65-27.31a1 1 0 000-.18 23.63 23.63 0 0110.52-5.17 47.87 47.87 0 00-.71 11.95zm22.44-6.24a38 38 0 00-20 4A44.42 44.42 0 0123.9 2.65c.61 0 1.16-.07 1.74-.07a23.62 23.62 0 0116.88 7.01zM7.18 13.21c.59 8.26 2.33 18.07 6.87 25a38.86 38.86 0 01-6.94 2.94 23.48 23.48 0 01.07-27.94zM8.6 43.48a42.39 42.39 0 008.76-4 1.2 1.2 0 00.41-.43c3.19-2 9-6.16 9.78-10.81a31.92 31.92 0 019.54-1.56A36.49 36.49 0 0134.47 35a25.42 25.42 0 01-17.11 14.39 24 24 0 01-8.76-5.91zm17.68 7.94a24.91 24.91 0 01-4.57-.42c8.49-3.44 13.07-10 15.56-15.93.11-.27.23-.54.33-.81a1.11 1.11 0 00.07-.16 38.87 38.87 0 002.07-7.28 22.86 22.86 0 0110.54 3.58 24.11 24.11 0 01-24 21.02z" />
    </svg>
  )
}

export default Volleyball

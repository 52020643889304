import React from "react"

const Track = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 49 56"
    >
      <path d="M45.88 53.68h-16a6.73 6.73 0 01-4.4-2L1.91 27.34l-.11-.1a1.08 1.08 0 00-1.47.1 1.18 1.18 0 000 1.66l23.59 24.35.23.23A8.92 8.92 0 0030.21 56H46a1.16 1.16 0 00-.13-2.31zM18 23a4 4 0 104 4 4 4 0 00-4-4zm0 5.71A1.71 1.71 0 1119.71 27 1.7 1.7 0 0118 28.71z" />
      <path d="M48.67 1.33a1.11 1.11 0 00-1.59 0L34.61 14l-.26.28a12.59 12.59 0 00-3.27 9.84h-1.85c-5.59 0-11.56-5.52-12.61-11.2a1 1 0 00.8.06 15.14 15.14 0 008.14-7.08l2.28-4.1.06-.13a1.24 1.24 0 00-.42-1.5 1 1 0 00-1.5.4L23.5 5a12.94 12.94 0 01-6.74 5.71 1.09 1.09 0 00-.36.22 1.12 1.12 0 00-1.94-.52L2.28 24.47A1.15 1.15 0 002.33 26l22.11 22.38A9.42 9.42 0 0030.8 51h14.1a1.12 1.12 0 001.1-.83l.85-3.17a5.32 5.32 0 00-3.77-6.34H42.51l-.35-.12-.39-.14a15.8 15.8 0 01-3.92-1.85A.88.88 0 0038 38a1.05 1.05 0 00-1.1-1H36a11.57 11.57 0 01-2.07-2.73A.82.82 0 0034 34a1 1 0 00-.62-.89 17.65 17.65 0 01-1.26-6.2 1.09 1.09 0 00.67.05 1.12 1.12 0 00.81-1.37 10.44 10.44 0 012.61-10L48.67 2.93l.1-.11a1.14 1.14 0 00-.1-1.49zM28.1 39h6.72a16.68 16.68 0 005.86 3.37l.42.14.62.21h.07l.23.06a3.85 3.85 0 00.47.08h.22a3.06 3.06 0 012 3.5L44 48.72H30.48a7.26 7.26 0 01-4.5-2L4.67 25.17l9.83-11.36v.18c1.58 6.48 8.22 12.37 14.69 12.37h.62v.16A20.93 20.93 0 0030.93 33H25a1 1 0 00-1 1 1.06 1.06 0 001.11 1h6.67A14.48 14.48 0 0033 37h-5a1 1 0 00-1 1 1.05 1.05 0 001.1 1z" />
    </svg>
  )
}

export default Track

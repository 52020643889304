import React, {useContext} from 'react'
import AthleteSignupContext from './context'
import tw from 'twin.macro'
import { ButtonLink } from '../Button'

const Complete = ({title, paragraph, active}) => {

    return (
      <div
      css={[
        tw`hidden py-6 px-4 lg:p-16 items-center`,
        "min-height: 550px",
        active && tw`flex`,
      ]}
    >
      <div css={[tw`lg:w-3/4`]}>
        <h4 css={[tw`text-4xl mb-6 md:mb-8 lg:mb-16`]}>
          {title}
        </h4>
        <p css={[tw`text-sm mb-4`]}>
          {paragraph}
        </p>
        <div>
          <ButtonLink primary to="/">
            Close
          </ButtonLink>
        </div>
      </div>
    </div>
    )
}

export default Complete
import { graphql, useStaticQuery } from "gatsby"

export const useAthletesRulesData = () => {
  const data = useStaticQuery(
    graphql`
      {
        markdownRemark(frontmatter: { slug: { eq: "rules-athletes" } }) {
          html
        }
      }
    `
  )
  return data.markdownRemark
}

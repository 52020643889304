import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import Button from "../Button"
import {
  setActiveSport,
  setResults,
  increaseStep,
  setLoadingResults,
  sortResults,
} from "./actions"
import PackageRow from "./PackageRow"
import SponsorSignupContext from "./context"
import { useSports } from "../../hooks/useSports"
import FormPage from "./FormPage"

const Packages = ({ index, title, paragraph, ctaText }) => {
  const [
    { active_sport, selected_package, active_step, results, loading_results },
    dispatch,
  ] = useContext(SponsorSignupContext)

  // COMMENT FOR SPORTS SELECTION
  const showResults = true
  useEffect(() => {
    if(!active_sport) return;
    ;(async () => {
      dispatch(setLoadingResults(true))
      try {
        const res = await fetch(`${process.env.GATSBY_API_URL}/packages/${active_sport.id}`, {
          headers: {
            "x-api-key": process.env.GATSBY_API_KEY,
          },
        })
        const json = await res.json()
        dispatch(setResults(json))
      } catch (err) {
        console.error(err)
      }

      dispatch(setLoadingResults(false))
    })()
  }, [active_sport])

  return (
    <FormPage
      title={title}
      paragraph={paragraph}
      nextButtonText={ctaText}
      nextButtonDisabled={!selected_package}
      nextButtonOnClick={() => dispatch(increaseStep())}
      wrapperCss={[
        tw`hidden flex-col h-full`,
        active_step === index && tw`flex`,
      ]}
    >
      <div css={[tw`overflow-auto`]}>
        {loading_results && <div css={[tw`h-80 p-8`]}>Loading Results...</div>}
        {results && results.length ? (
          results.map((pkg, i) => (
            <PackageRow key={i} data={pkg} shaded={i % 2} />
          ))
        ) : (
          <div
            css={[
              tw`bg-white h-auto md:h-80 flex flex-col items-center justify-center text-4xl text-gray-dark font-black italic uppercase`,
              "min-height: 18rem;",
            ]}
          >
            <p>No Results</p>
          </div>
        )}
      </div>
    </FormPage>
  )
}

export default Packages

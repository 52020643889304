import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import SponsorSignupContext from "./context"
import FormPage from "./FormPage"
import { Input } from "../Form"
import "./sq-payment.css"
import { increaseStep } from "./actions"

const Payment = ({ index, title, paragraph, ctaText }) => {
  const [
    {
      selected_package,
      selected_pricing_level,
      active_step,
      company_name,
      company_type,
      company_website,
      email,
      name,
      phone,
      mailing_street_address,
      mailing_city,
      mailing_state,
      mailing_zip_code,
      use_case,
      use_name,
      type, 
      active_sport
    },
    dispatch,
  ] = useContext(SponsorSignupContext)

  const [card, setCard] = useState()
  const [cardHolderName, setCardHolderName] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const defaultError =
    "Something's gone wrong. Check your card information and try again."

  useEffect(() => {
    let sqPaymentScript = document.createElement("script")
    sqPaymentScript.src = process.env.GATSBY_SQUARE_URL
    sqPaymentScript.type = "text/javascript"
    sqPaymentScript.async = true
    sqPaymentScript.onload = init
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript)
  }, [])

  async function createPayment(token) {
    const body = JSON.stringify({
      payment: {
        card_holder_name: cardHolderName,
        payment_token: token,
        package_id: selected_package?.id,
        pricing_level_title: selected_pricing_level.title,
        pricing_level_price: selected_pricing_level.price,
        sport_id: active_sport?.id,
      },
      sponsor: {
        company_name: company_name,
        company_type: company_type,
        company_website: company_website,
        email: email,
        name: name,
        phone: phone,
        mailing_street_address: mailing_street_address,
        mailing_city: mailing_city,
        mailing_state: mailing_state,
        mailing_zip_code: mailing_zip_code,
        use_case: use_case,
        use_name: use_name,
        type: type,
      },
    })

    const paymentResponse = await fetch(
      `${process.env.GATSBY_API_URL}/sponsors`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.GATSBY_API_KEY,
        },
        body,
      }
    )

    if (!paymentResponse.ok) throw new Error()
  }

  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize()
    if (tokenResult.status === "OK") {
      return tokenResult.token
    } else {
      throw new Error()
    }
  }

  const init = async () => {
    if (!window.Square) {
      setError(defaultError)
    }

    if (card) return

    let payments
    try {
      payments = window.Square.payments(
        process.env.GATSBY_SQUARE_APP_ID,
        process.env.GATSBY_SQUARE_LOCATION_ID
      )
      const card = await payments.card()
      await card.attach("#card-container")
      setCard(card)
    } catch (e) {
      setError(defaultError)
      return
    }
  }

  const handleFormSubmit = async event => {
    event.preventDefault()

    setError("")
    if (success) {
      setError("A payment has already been received")
      return
    }
    if (!cardHolderName) {
      setError("Card holder name is required")
      return
    }

    setLoading(true)
    try {
      const token = await tokenize(card)
      await createPayment(token)
      setSuccess(true)
      dispatch(increaseStep())
    } catch (e) {
      setError(defaultError)
    }
    setLoading(false)
  }

  return (
    <FormPage
      title={title}
      paragraph={paragraph}
      nextButtonText={ctaText}
      nextButtonOnClick={handleFormSubmit}
      nextButtonDisabled={loading}
      show={index === active_step}
    >
      <div css={[tw`bg-white px-4 py-8 md:px-16 md:py-12 text-gray-dark`]}>
        <div css={[tw`flex flex-row mb-4`]}>
          <Input
            name="card-holder-name"
            label="Card Holder Name"
            value={cardHolderName}
            onChange={e => {
              setCardHolderName(e.target.value)
            }}
            css={[tw`border-gray-300 w-full`]}
          />
        </div>

        <div id="card-container"></div>
        {error && (
          <div css={[tw`text-center text-sm text-primary`]}>{error}</div>
        )}
        {loading && (
          <div css={[tw`text-center text-blue-400`]}>
            <svg
              css={[
                tw`inline-block animate-spin -ml-1 mr-3 h-5 w-5 text-blue-400`,
              ]}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                css={[tw`opacity-25`]}
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                css={[tw`opacity-75`]}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Payment is processing. Do not close this window.
          </div>
        )}
      </div>
      <p css={[tw`pt-4 px-4 md:px-16`]}>By becoming a sponsor you will have the opportunity to participate with TTC to build your brand.</p>
    </FormPage>
  )
}

export default Payment

import { graphql, useStaticQuery } from "gatsby"

export const useFormsData = formName => {
  const data = useStaticQuery(
    graphql`
      {
        allFormsYaml {
          edges {
            node {
              title: form
              paragraph
              pages {
                ctaText: cta_button_text
                ctaLink: cta_button_link
                paragraph
                title
                component
              }
            }
          }
        }
      }
    `
  )

  return data.allFormsYaml.edges.filter(
    ({ node }) => node.title === formName
  )[0].node
}

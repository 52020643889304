import { graphql, useStaticQuery } from "gatsby"

export const usePrimaryNavigation = () =>
  useStaticQuery(graphql`
    {
      navigation: navigationYaml(position: { eq: "primary" }) {
        items {
          button
          label
          url
        }
      }
    }
  `)

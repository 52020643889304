import React, {useContext} from 'react'
import SupporterSignupContext from './context'
import FormPage from './FormPage'
import { increaseStep } from './actions'
import tw from 'twin.macro'
import { useSupporterIntroData } from '../../hooks/useSupporterIntroData'

const Intro = ({title, paragraph, ctaText, index}) => {
    const [{active_step}, dispatch] = useContext(SupporterSignupContext)
    const content = useSupporterIntroData();
    return (
        <FormPage
            show={active_step === index}
            title={title}
            paragraph={paragraph}
            nextButtonText={ctaText}
            nextButtonOnClick={() => dispatch(increaseStep())}
            activeStep={active_step}
        >
            <div 
                css={[tw`p-6 text-white lg:px-16`]}
                dangerouslySetInnerHTML={{ __html: content.html }}
            >
            </div> 
        </FormPage>
    )
}

export default Intro

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import tw from "twin.macro"
import Navigation from "../Navigation"
import Logo from "../Logo"

const Header = ({ siteTitle }) => (
  <header css={[tw`bg-gray-light`]}>
    <nav tw="container mx-auto flex items-center py-2 pr-4">
      <div tw="py-1 flex flex-1">
        <Link
          tw="flex items-center font-semibold text-gray-400 cursor-pointer"
          to="/"
          aria-label="Home"
        >
          <Logo css={[tw`h-16 ml-2`]} />
        </Link>
      </div>
      <Navigation.Primary black />
      <div tw="flex lg:hidden flex-1 justify-end">
        <Navigation.Mobile />
      </div>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"

const Gymnastics = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="nonzero"
      fill="currentcolor"
      viewBox="0 0 51 64"
    >
      <path d="M39.76 24zM39.76 24c0 4.22-.46 1.41 3.79 5a13.76 13.76 0 014.35 14.18 14.83 14.83 0 01-15.28 10.48A16.72 16.72 0 0034 47.28c.08-6.21-4.84-13.59-10.92-15.28l.07 2.43a14.2 14.2 0 01-6.08 27.15 14.49 14.49 0 01-14.23-10.9c-1.76-6.86 2.08-13.31 8.05-16.22V32c-1.64.33-4.88 2.69-5.73 3.48a16.42 16.42 0 00-4.25 17.2c3.15 8.49 13.23 14.15 22.93 9.84a17 17 0 007.6-6.6c8.08.64 14.8-3.08 18-9.85 4.24-8.17-.62-19.4-9.68-22.07zM18.17 37.19z" />
      <path d="M20 46.91c1.34 2.71 3.29 4.35 5.78 6.09A5.26 5.26 0 0027 51c-5.67-4.24-7.14-8.3-6-15-.07.12 0 1.23-2.84 1.19A16 16 0 0020 46.91zM29 27.23V24c-2 .72-6.34 4.51-7 5.81L24.27 31c1.14-.84 2.32-2.66 4.73-3.77zM34.09.01L34 20.99l2.92.01L37 0l-2.91.01zM12.22 35h5.56A1.22 1.22 0 0019 33.78v-5.56A1.2 1.2 0 0017.93 27L18 .3h-2.91L15 27h-2.78A1.22 1.22 0 0011 28.22v5.56A1.22 1.22 0 0012.22 35zM32.22 29h5.56A1.22 1.22 0 0039 27.78v-5.56A1.22 1.22 0 0037.78 21h-5.56A1.22 1.22 0 0031 22.22v5.56A1.22 1.22 0 0032.22 29z" />
    </svg>
  )
}

export default Gymnastics

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"
import GlobalStyles from "../GlobalStyles"
import SponsorSignup from "../SponsorSignup"
import AthleteSignup from "../AthleteSignup"
import IndividualSupporterSignup from "../IndividualSupporterSignup"

export const ModalContext = createContext()

const Layout = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null)

  return (
    <>
      <ModalContext.Provider
        value={{
          activeModal,
          setActiveModal,
        }}
      >
        <ModalContext.Consumer>
          {({ activeModal }) => (
            <>
              <SponsorSignup open={activeModal === "sponsor"} />
              <IndividualSupporterSignup open={activeModal === "supporter"} />
              <AthleteSignup open={activeModal === "athlete"} />
            </>
          )}
        </ModalContext.Consumer>
        <GlobalStyles />
        <Header siteTitle={`where UA athletes & sponsors connect`} />
        <main>{children}</main>
        <Footer siteTitle={`where UA athletes & sponsors connect`} />
      </ModalContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import {
  INCREASE_STEP,
  DECREASE_STEP,
  UDPATE_FIELD,
  SET_STEP,
  CLEAR_STATE,
  FORM_SUCCESS,
  FORM_SUBMITTING,
  FORM_ERROR,
} from "./actions"

export const initialState = {
  active_step: 0,
  name: "",
  email: "",
  phone: "",
  sport_id: "",
  hometown_city: "",
  hometown_state: "",
  opt_in: false,
  form_submitting: false,
  form_error: false,
  form_success: false,
}

const athleteSignupReducer = (state, action) => {
  switch (action.type) {
    case UDPATE_FIELD:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }

    case INCREASE_STEP:
      return {
        ...state,
        active_step: state.active_step + 1,
      }

    case DECREASE_STEP:
      return {
        ...state,
        active_step: state.active_step - 1,
      }

    case SET_STEP:
      return {
        ...state,
        active_step: action.payload,
      }

    case FORM_SUBMITTING:
      return {
        ...state,
        form_submitting: action.payload,
      }

    case FORM_SUCCESS:
      return {
        ...state,
        form_success: action.payload,
      }

    case FORM_ERROR:
      return {
        ...state,
        form_error: action.payload,
      }

    case CLEAR_STATE:
      return {
        ...initialState,
      }

    default:
      throw new Error()
  }
}

export default athleteSignupReducer

import { graphql, useStaticQuery } from "gatsby"

export const useSponsorRulesData = () => {
    const data = useStaticQuery(
        graphql`
          {
            markdownRemark(frontmatter: { slug: { eq: "rules-sponsors" } }) {
              html
            }
          }
        `
      )
      return data.markdownRemark
}